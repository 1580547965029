import React, { useEffect, useMemo } from "react";
import { Form, Row, Col, Button, Card, Badge } from "react-bootstrap";
import JoditEditor, { Jodit } from "jodit-react";
import { useFormik } from "formik";
import { Fetch, catchError } from "../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import ImageUploader from "../../../utils/image-uploader";
import { AlertInfo, AlertSuccess } from "../../../components/alert";
import { loadingAction } from "../../../redux/actions";
import MediaUploader from "../../../utils/media-uploader";
import { validateModel } from "./validationSchema";
import { useHistory } from "react-router-dom";
import { BASE_URL, EstadoContenido } from "../../../utils/constants";
import { eliminarImagen, removeSpecial } from "../../../utils/tools";
import CategoriasComponent from "../../../components/categoria";
import LayoutJodit from "../../../components/customizer/layout-jodit";



const NuevaNovedad = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);
  const isLoading = useSelector((state) => state.loading);
  const permissions = useSelector((state) => state.permissions);
  const canPublish = permissions.novedades.aprobar.enabled;
  const history = useHistory();
  const [textoRecomienda, setTextoRecomienda] = React.useState(
    "Se recomienda una imagen de 600px de ancho x 270px de alto. En formato JPG o PNG."
  );

  const [layoutJodit, setLayoutJodit] = React.useState("PC");

  const [alto, setAlto] = React.useState(270);
  const [categoriasAsociadas, setCategoriasAsociadas] = React.useState([]);
  const [subcategoriasSeleccionadas, setSubCategoriasSeleccionadas] =
    React.useState([]);

    const editor = React.useRef(null);

  // function preparePaste(jodit) {
  //   jodit.e.on(
  //     "paste",
  //     (e) => {
  //       jodit.e.stopPropagation("paste");
  //       let plainText = Jodit.modules.Helpers.getDataTransfer(e).getData(
  //         Jodit.constants.TEXT_PLAIN
  //       );
  //       // plainText = plainText.replace(/(^|;)\s*font-[^;]+/g, "");
  //       // let regex = /<(?!(\/\s*)?(div|b|i|em|strong|u|p)[>,\s])([^>])*>/g;
  //       // plainText = plainText.replace(regex, '');
  //       //plainText = plainText.normalize("NFKC").replaceAll("\n", "<br/>");
  //       //plainText=removeSpecial(plainText);

  //       jodit.s.insertHTML(plainText);
  //       return false;
  //     },
  //     { top: true }
  //   );
  // }
  // Jodit.plugins.add("preparePaste", preparePaste);

  const config = useMemo(
    () => ({
      readonly: false,
      height: 500,
      language: "es",
      disablePlugins: ["table"],
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
      enableDragAndDropFileToEditor: true,
      // uploader: {
      //     insertImageAsBase64URI: true
      // },
      uploader: {
        url: BASE_URL + "/upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        data: null,
        filesVariableName: function (i) {
          return "files";
        },
        withCredentials: false,
        pathVariableName: "path",
        format: "json",
        method: "POST",
        prepareData: function (formData) {
          formData.append("id", 1);
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join("\n");
        },
        process: function (resp) {
          return resp;
        },
        defaultHandlerSuccess: function (resp) {
          var imagenesPermitidas = ["image/jpeg", "image/png"];
          for (var i = 0; i < resp.length; i++) {
            if (imagenesPermitidas.indexOf(resp[i].mime) !== -1) {
              var img1 = new Image();
              img1.src = resp[i]["url"];
              img1.alt = "imagen";
              img1.className = "img-fluid";
              this.s.insertImage(img1);
            } else if (resp[i].mime === "application/pdf") {
              const enlace = document.createElement("a");
              enlace.setAttribute("href", resp[i].url);
              const contenido = document.createTextNode(resp[i].name);
              // add the text node to the newly created div
              enlace.appendChild(contenido);
              this.s.insertNode(enlace);
            } else {
              AlertInfo("Tipo de archivo no permitido");
            }
          }
        },
      },
    }),
    []
  );

  const submit = async (values) => {
    // values.creado_por = user.id;
    // values.actualizado_por = user;

    if (!subcategoriasSeleccionadas.length) {
      AlertInfo("Debe de seleccionar por lo menos una subcategoría");
      return;
    }

    values.subcategorias = subcategoriasSeleccionadas;

    dispatch(loadingAction(true));

    values.published_at = null;
    values.estado = EstadoContenido.EnRevision;

    values.descripcion = removeSpecial(values.descripcion);

    try {
      await Fetch("novedades", "post", values);

      AlertInfo(
        "Novedad creada con éxito.<br/>Recuerde que falta su confirmacion para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar"
      );
      history.push("/novedades/lista-borradores");

      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const { values, handleSubmit, handleChange, setFieldValue, errors } =
    useFormik({
      initialValues: {
        titulo: "",
        descripcionCorta: "",
        descripcion: "",
        imagen: "",
        destacado: false,
        slug: "",
        orden: 0,
        habilitado: true,
        locale: "es",
        eliminado: false,
        localizations: [],
        published_at: "2022-05-17T04:14:37.397Z",
        created_by: user.id,
        updated_by: user,
      },
      enableReinitialize: true,
      validationSchema: validateModel,
      onSubmit: (values) => submit(values),
    });

  const handleBlurTitulo = async (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const dataToApi = {
      contentTypeUID: "application::novedades.novedades",
      field: "slug",
      data: {
        titulo: value,
        slug: "",
      },
    };
    setFieldValue(name, value, false);
    try {
      const { data } = await Fetch(
        "content-manager/uid/generate",
        "post",
        dataToApi
      );
      setFieldValue("slug", data.data, false);
    } catch (error) {
      console.error("mostrando error", error);
    }
  };

  const handleChangeJoiEditor = (data, field) => {
    setFieldValue(field, data, false);
  };

  const handleChangeDestacado = (e) => {
    if (e.target.value === "true") {
      setTextoRecomienda(
        "Para novedades destacadas, se recomienda una imagen de 600px de ancho x 270px de alto. En formato JPG o PNG."
      );
      setAlto(270);
    } else {
      setTextoRecomienda(
        "Se recomienda una imagen de 600px de ancho x 270px de alto. En formato JPG o PNG."
      );
      setAlto(270);
    }
    setFieldValue(e.target.name, e.target.value, false);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Row>
                  <Col>Nueva novedad</Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Título de la novedad</label>
                      <input
                        type="text"
                        name="titulo"
                        onChange={handleChange}
                        onBlur={(e) => handleBlurTitulo(e)}
                        value={values.titulo}
                        className="form-control "
                      />
                      <div className="text-danger">{errors?.titulo}</div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Url</label>
                      <input
                        type="text"
                        name="slug"
                        className="form-control"
                        value={values.slug}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <h3>Información del contenido</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Destacado</label>
                      <select
                        className="form-control"
                        name="destacado"
                        value={values.destacado}
                        onChange={handleChangeDestacado}
                      >
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                      <div className="text-danger">{errors?.destacado}</div>
                    </Form.Group>
                  </Col>
                </Row>

                {Object.keys(values.imagen).length > 0 && (
                  <>
                    <Row>
                      <Col>
                        <Form.Group>
                          <img
                            src={values.imagen.url}
                            alt="vista-previa"
                            className="img-fluid w-50"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Button
                          type="button"
                          style={{ marginBottom: "10px" }}
                          onClick={() => {
                            eliminarImagen(values.imagen.id).then(
                              (response) => {
                                setFieldValue("imagen", "", false);
                              }
                            ).catch(error=>{}); ;
                          }}
                          className="btn btn-danger btn-icon mt-2"
                        >
                          <i className="fa fa-trash-o " />
                          Eliminar
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Imagen</label>
                      <MediaUploader
                        key={values.imagen}
                        setFieldValue={setFieldValue}
                        field={"imagen"}
                        tipoMedia="imagen"
                        ancho={600}
                        alto={alto}
                        value={values.imagen ? 100 : 0}
                      />
                      <div className="text-danger">{errors?.imagen}</div>
                      <span className="text-muted" id="texto-recomienda">
                        {textoRecomienda}
                      </span>
                    </Form.Group>
                  </Col>
                </Row>
                <hr />

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Descripción corta</label>
                      <textarea
                        name="descripcionCorta"
                        onChange={handleChange}
                        className="form-control"
                        value={values.descripcionCorta}
                        maxLength={90}
                      ></textarea>
                      <div className="text-danger">
                        {errors?.descripcionCorta}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
                    <Form.Group>
                      <label>Descripción</label>
                      <LayoutJodit
                setLayoutJodit={setLayoutJodit}
                layoutJodit={layoutJodit}
              />
                      <JoditEditor
                        ref={editor}
                        config={config}
                        value={values.descripcion}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) =>{
                          console.log("NUEVO CONTENIDO",newContent)
                          return handleChangeJoiEditor(newContent, "descripcion")
                        }
                        }
                      />
                      
                      <div className="text-danger">{errors?.descripcion}</div>
                    </Form.Group>
                  </Col>
                </Row>

                <CategoriasComponent
                  setCategoriasAsociadas={setCategoriasAsociadas}
                  categoriasAsociadas={categoriasAsociadas}
                  subcategoriasSeleccionadas={subcategoriasSeleccionadas}
                  setSubCategoriasSeleccionadas={setSubCategoriasSeleccionadas}
                  seccion="novedades"
                />

                <Button
                  type="button"
                  onClick={() => handleSubmit()}
                  disabled={isLoading}
                  className="btn btn-success btn-icon mt-2"
                >
                  <i className="fa fa-floppy-o " />
                  Guardar
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
export default NuevaNovedad;
