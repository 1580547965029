import "devextreme/dist/css/dx.light.css";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";

import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from "./redux/store";

// Import as a module in your JS
import "react-bootstrap-typeahead/css/Typeahead.css";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Layout from "./layouts";

// Components
import PrivateRoute from "./routes/PrivateRoute";

// Auth
import Login from "./pages/auth/login";
import Register from "./pages/auth/register";
import ResetPassword from "./pages/auth/reset-password";

// Dashboard
import Dashboard from "./pages/dashboard";

//Menus
import ListaMenus from "./pages/menus/lista";
import ListaBorradores from "./pages/menus/lista-borradores";
import ListaPorEliminarMenu from "./pages/menus/lista-por-eliminar";
import ListaMenusX from "./pages/menus/listax";
import NuevoMenu from "./pages/menus/nuevo";
import VerMenu from "./pages/menus/ver";
import VerBorradorMenu from "./pages/menus/ver-borrador";
import VerParaEliminarMenu from "./pages/menus/ver-para-eliminar";
import VerMenuX from "./pages/menus/verx";

//Paginas
import ListarPaginas from "./pages/paginas/lista";
import ListaBorradoresPaginas from "./pages/paginas/lista-borradores";
import ListaPorEliminarPaginas from "./pages/paginas/lista-por-eliminar";
import ListarPaginasX from "./pages/paginas/listax";
import NuevaPagina from "./pages/paginas/nuevo-step1";
import NuevaPaginaTemplate from "./pages/paginas/nuevo-step2";
import VerPaginaTemplate from "./pages/paginas/ver";
import VerBorradorPaginaTemplate from "./pages/paginas/ver-borrador";
import VerParaEliminarPagina from "./pages/paginas/ver-para-eliminar";
import VerPaginaTemplateX from "./pages/paginas/verx";

import createPassword from "./pages/auth/create-password";
import updatePassword from "./pages/auth/update-password";

//Novedades
import ListaNovedades from "./pages/novedades/lista";
import ListaBorradoresNovedades from "./pages/novedades/lista-borradores";
import ListaPorEliminar from "./pages/novedades/lista-por-eliminar";
import ListaNovedadesX from "./pages/novedades/listax";
import NuevaNovedad from "./pages/novedades/nuevo";
import VerNovedad from "./pages/novedades/ver";
import VerBorradorNovedad from "./pages/novedades/ver-borrador";
import VerNovedadX from "./pages/novedades/verx";

//Especialidades
import ListaEspecialidades from "./pages/especialidades/lista";
import ListaBorradoresEspecialidades from "./pages/especialidades/lista-borradores";
import ListaPorEliminarEspecialidad from "./pages/especialidades/lista-por-eliminar";
import ListaEspecialidadesX from "./pages/especialidades/listax";
import NuevaEspecialidad from "./pages/especialidades/nuevo";
import VerEspecialidad from "./pages/especialidades/ver";
import VerBorradorEspecialidad from "./pages/especialidades/ver-borrador";
import VerEspecialidadX from "./pages/especialidades/verx";

//Configuracion Redes Sociales
import VerSocialNetwork from "./pages/social-networks/ver";
import VerSocialNetworkX from "./pages/social-networks/verx";

//Centros Médicos
import ListaCentrosMedicos from "./pages/centros-medicos/lista";
import ListaBorradoresCentroMedico from "./pages/centros-medicos/lista-borradores";
import ListaPorEliminarCentro from "./pages/centros-medicos/lista-por-eliminar";
import ListaCentrosMedicosX from "./pages/centros-medicos/listax";
import NuevoCentroMedico from "./pages/centros-medicos/nuevo";
import VerCentroMedico from "./pages/centros-medicos/ver";
import VerBorradorCentroMedico from "./pages/centros-medicos/ver-borrador";
import VerParaEliminarCentro from "./pages/centros-medicos/ver-para-eliminar";
import VerCentroMedicoX from "./pages/centros-medicos/verx";

//Nuestros Accessos
import ListaNuestrosAccesos from "./pages/nuestros-accesos/lista";
import ListaBorradoresNuestrosAccesos from "./pages/nuestros-accesos/lista-borradores";
import ListaPorEliminarAcceso from "./pages/nuestros-accesos/lista-por-eliminar";
import ListaAccesoX from "./pages/nuestros-accesos/listax";
import NuevoAcceso from "./pages/nuestros-accesos/nuevo";
import VerAcceso from "./pages/nuestros-accesos/ver";
import VerBorradorAcceso from "./pages/nuestros-accesos/ver-borrador";
import VerParaEliminarAcceso from "./pages/nuestros-accesos/ver-para-eliminar";
import VerAccesosX from "./pages/nuestros-accesos/verx";

//Configuracion - Home Slide
import ListarHomeSlides from "./pages/configuracion/home-slides/lista";
import ListaBorradoresHomeSlides from "./pages/configuracion/home-slides/lista-borradores";
import ListaPorEliminarSlide from "./pages/configuracion/home-slides/lista-por-eliminar";
import ListarHomeSlidesX from "./pages/configuracion/home-slides/listax";
import NuevoHomeSlide from "./pages/configuracion/home-slides/nuevo";
import VerHomeSlide from "./pages/configuracion/home-slides/ver";
import VerBorradorHomeSlide from "./pages/configuracion/home-slides/ver-borrador";
import VerParaEliminarSlide from "./pages/configuracion/home-slides/ver-para-eliminar";
import VerHomeSlideX from "./pages/configuracion/home-slides/verx";

//Configuracion Home Estudia
import VerHomeEstudia from "./pages/configuracion/home-estudia/ver";
import VerHomeEstudiaX from "./pages/configuracion/home-estudia/verx";

//Configuracion Home Whatsapp
import VerHomeWhatsapp from "./pages/configuracion/home-whatsapp/ver";
import VerHomeWhatsappX from "./pages/configuracion/home-whatsapp/verx";

//Pop up
import ListaPopup from "./pages/popup/lista";
import ListaBorradoresPopup from "./pages/popup/lista-borradores";
import ListaPorEliminarPopup from "./pages/popup/lista-por-eliminar";
import ListaPopupX from "./pages/popup/listax";
import NuevoPopup from "./pages/popup/nuevo";
import VerPopup from "./pages/popup/ver";
import VerBorradorPopup from "./pages/popup/ver-borrador";
import VerParaEliminarPopup from "./pages/popup/ver-para-eliminar";
import VerPopupX from "./pages/popup/verx";

//Prensa
import ListaPrensa from "./pages/prensa/lista";
import ListaBorradoresPrensa from "./pages/prensa/lista-borradores";
import ListaPorEliminarPrensa from "./pages/prensa/lista-por-eliminar";
import ListaPrensaX from "./pages/prensa/listax";
import NuevoPrensa from "./pages/prensa/nuevo";
import VerPrensa from "./pages/prensa/ver";
import VerBorradorPrensa from "./pages/prensa/ver-borrador";
import VerParaEliminarPrensa from "./pages/prensa/ver-para-eliminar";
import VerPrensaX from "./pages/prensa/verx";

//Categorias
import ListaCategorias from "./pages/categorias/lista";
import EditarSubcategorias from "./pages/categorias/editar-subcategorias";

import VerParaEliminar from "./pages/novedades/ver-para-eliminar";
import * as serviceWorker from "./serviceWorker";
import VerParaEliminarEspecialidad from "./pages/especialidades/ver-para-eliminar";
import { Jodit } from "jodit-react";
import { removeSpecialJodit, isHTML } from "./utils/tools";

//Usuarios CMS
import UsuarioCMS from "./pages/usuarios-cms/nuevo-usuario";
import ListaUsuariosCMS from "./pages/usuarios-cms/lista";

let persistor = persistStore(store);

function preparePaste(jodit) {
  jodit.e.on(
    "paste",
    (e) => {
      jodit.e.stopPropagation("paste");
      let plainText = Jodit.modules.Helpers.getDataTransfer(e).getData(
        Jodit.constants.TEXT_PLAIN
      );

      if (!isHTML(plainText)) {
        plainText = plainText.normalize("NFKC");
        plainText = plainText.replace(/\r\n/g, "\n");
        // plainText = removeSpecialJodit(plainText);
      }

      // Dividir el texto en líneas separadas
      const lines = plainText.split("\n");

      // Insertar cada línea como un nuevo nodo
      lines.forEach((line) => {
        if (line.startsWith("https://") || line.startsWith("http://")) {
          jodit.s.insertHTML(`<a href="${line}">${line}</a>`);
        } else {
          jodit.s.insertHTML(`<p>${line}</p>`);
        }
      });

      return false;
    },
    { top: true }
  );
}
Jodit.plugins.add("preparePaste", preparePaste);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <BrowserRouter basename={"/"}>
          <Switch>
            <Route
              exact
              path="/auth"
              render={() => <Redirect to="/auth/login" />}
            />
            <Route path={`/auth/login`} component={Login} />
            <Route path={`/auth/register`} component={Register} />
            <Route path={`/auth/forget-password`} component={ResetPassword} />

            <Route path={`/auth/create-password`} component={createPassword} />
            <Route path={`/auth/update-password`} component={updatePassword} />

            <Layout name="backend">
              <Route
                exact
                path="/"
                render={() => <Redirect to="/dashboard" />}
              />
              <PrivateRoute path={`/dashboard`} component={Dashboard} />

              {/* Menus */}
              <PrivateRoute path={`/menus/lista`} component={ListaMenus} />
              <PrivateRoute path={`/menus/listax`} component={ListaMenusX} />
              <PrivateRoute
                path={`/menus/en-revision`}
                component={ListaBorradores}
              />
              <PrivateRoute
                path={`/menus/lista-por-eliminar`}
                component={ListaPorEliminarMenu}
              />
              <PrivateRoute path={`/menus/nuevo`} component={NuevoMenu} />
              <PrivateRoute path={`/menus/ver/:id`} component={VerMenu} />
              <PrivateRoute
                path={`/menus/ver-borrador/:id`}
                component={VerBorradorMenu}
              />
              <PrivateRoute path={`/menus/aprobar/:id`} component={VerMenuX} />
              <PrivateRoute
                path={`/menus/ver-para-eliminar/:id`}
                component={VerParaEliminarMenu}
              />

              {/* Paginas */}
              <PrivateRoute path={`/pages/lista`} component={ListarPaginas} />
              <PrivateRoute
                path={`/pages/lista-borradores`}
                component={ListaBorradoresPaginas}
              />
              <PrivateRoute path={`/pages/listax`} component={ListarPaginasX} />
              <PrivateRoute
                path={`/pages/lista-por-eliminar`}
                component={ListaPorEliminarPaginas}
              />
              <PrivateRoute path={`/pages/nuevo`} component={NuevaPagina} />
              <PrivateRoute
                path={`/pages/nueva-pagina/:template`}
                component={NuevaPaginaTemplate}
              />
              <PrivateRoute
                path={`/pages/ver/:template/:id`}
                component={VerPaginaTemplate}
              />
              <PrivateRoute
                path={`/pages/ver-borrador/:template/:id`}
                component={VerBorradorPaginaTemplate}
              />
              <PrivateRoute
                path={`/pages/ver-para-eliminar/:template/:id`}
                component={VerParaEliminarPagina}
              />
              <PrivateRoute
                path={`/pages/aprobar/:template/:id`}
                component={VerPaginaTemplateX}
              />

              {/* Novedades */}
              <PrivateRoute
                path={`/novedades/lista`}
                component={ListaNovedades}
              />
              <PrivateRoute
                path={`/novedades/listax`}
                component={ListaNovedadesX}
              />
              <PrivateRoute
                path={`/novedades/lista-por-eliminar`}
                component={ListaPorEliminar}
              />
              <PrivateRoute
                path={`/novedades/lista-borradores`}
                component={ListaBorradoresNovedades}
              />
              <PrivateRoute
                path={`/novedades/nuevo`}
                component={NuevaNovedad}
              />
              <PrivateRoute
                path={`/novedades/ver/:id`}
                component={VerNovedad}
              />
              <PrivateRoute
                path={`/novedades/ver-borrador/:id`}
                component={VerBorradorNovedad}
              />
              <PrivateRoute
                path={`/novedades/aprobar/:id/`}
                component={VerNovedadX}
              />
              <PrivateRoute
                path={`/novedades/ver-para-eliminar/:id/`}
                component={VerParaEliminar}
              />

              {/* Especilidades */}
              <PrivateRoute
                path={`/especialidades/lista`}
                component={ListaEspecialidades}
              />
              <PrivateRoute
                path={`/especialidades/listax`}
                component={ListaEspecialidadesX}
              />
              <PrivateRoute
                path={`/especialidades/lista-por-eliminar`}
                component={ListaPorEliminarEspecialidad}
              />
              <PrivateRoute
                path={`/especialidades/lista-borradores`}
                component={ListaBorradoresEspecialidades}
              />
              <PrivateRoute
                path={`/especialidades/nuevo`}
                component={NuevaEspecialidad}
              />
              <PrivateRoute
                path={`/especialidades/ver/:id`}
                component={VerEspecialidad}
              />
              <PrivateRoute
                path={`/especialidades/ver-borrador/:id`}
                component={VerBorradorEspecialidad}
              />
              <PrivateRoute
                path={`/especialidades/aprobar/:id/`}
                component={VerEspecialidadX}
              />
              <PrivateRoute
                path={`/especialidades/ver-para-eliminar/:id/`}
                component={VerParaEliminarEspecialidad}
              />

              {/* Centros Medicos */}
              <PrivateRoute
                path={`/centros-medicos/lista`}
                component={ListaCentrosMedicos}
              />
              <PrivateRoute
                path={`/centros-medicos/listax`}
                component={ListaCentrosMedicosX}
              />
              <PrivateRoute
                path={`/centros-medicos/lista-borradores`}
                component={ListaBorradoresCentroMedico}
              />
              <PrivateRoute
                path={`/centros-medicos/nuevo`}
                component={NuevoCentroMedico}
              />
              <PrivateRoute
                path={`/centros-medicos/ver/:id`}
                component={VerCentroMedico}
              />
              <PrivateRoute
                path={`/centros-medicos/ver-borrador/:id`}
                component={VerBorradorCentroMedico}
              />
              <PrivateRoute
                path={`/centros-medicos/ver-para-eliminar/:id`}
                component={VerParaEliminarCentro}
              />
              <PrivateRoute
                path={`/centros-medicos/aprobar/:id`}
                component={VerCentroMedicoX}
              />
              <PrivateRoute
                path={`/centros-medicos/lista-por-eliminar`}
                component={ListaPorEliminarCentro}
              />

              {/* Nuestros Accesos */}
              <PrivateRoute
                path={`/nuestros-accesos/lista`}
                component={ListaNuestrosAccesos}
              />
              <PrivateRoute
                path={`/nuestros-accesos/lista-borradores`}
                component={ListaBorradoresNuestrosAccesos}
              />
              <PrivateRoute
                path={`/nuestros-accesos/nuevo`}
                component={NuevoAcceso}
              />
              <PrivateRoute
                path={`/nuestros-accesos/listax`}
                component={ListaAccesoX}
              />
              <PrivateRoute
                path={`/nuestros-accesos/ver/:id`}
                component={VerAcceso}
              />
              <PrivateRoute
                path={`/nuestros-accesos/ver-borrador/:id`}
                component={VerBorradorAcceso}
              />
              <PrivateRoute
                path={`/nuestros-accesos/aprobar/:id`}
                component={VerAccesosX}
              />
              <PrivateRoute
                path={`/nuestros-accesos/lista-por-eliminar`}
                component={ListaPorEliminarAcceso}
              />
              <PrivateRoute
                path={`/nuestros-accesos/ver-para-eliminar/:id`}
                component={VerParaEliminarAcceso}
              />

              {/* Popup */}
              <PrivateRoute path={`/popup/lista`} component={ListaPopup} />
              <PrivateRoute
                path={`/popup/lista-borradores`}
                component={ListaBorradoresPopup}
              />
              <PrivateRoute path={`/popup/nuevo`} component={NuevoPopup} />
              <PrivateRoute path={`/popup/listax`} component={ListaPopupX} />
              <PrivateRoute path={`/popup/ver/:id`} component={VerPopup} />
              <PrivateRoute
                path={`/popup/ver-borrador/:id`}
                component={VerBorradorPopup}
              />
              <PrivateRoute path={`/popup/aprobar/:id`} component={VerPopupX} />
              <PrivateRoute
                path={`/popup/lista-por-eliminar`}
                component={ListaPorEliminarPopup}
              />
              <PrivateRoute
                path={`/popup/ver-para-eliminar/:id`}
                component={VerParaEliminarPopup}
              />

              {/* Prensa */}
              <PrivateRoute path={`/prensa/lista`} component={ListaPrensa} />
              <PrivateRoute
                path={`/prensa/lista-borradores`}
                component={ListaBorradoresPrensa}
              />
              <PrivateRoute path={`/prensa/nuevo`} component={NuevoPrensa} />
              <PrivateRoute path={`/prensa/listax`} component={ListaPrensaX} />
              <PrivateRoute path={`/prensa/ver/:id`} component={VerPrensa} />
              <PrivateRoute
                path={`/prensa/ver-borrador/:id`}
                component={VerBorradorPrensa}
              />
              <PrivateRoute
                path={`/prensa/aprobar/:id`}
                component={VerPrensaX}
              />
              <PrivateRoute
                path={`/prensa/lista-por-eliminar`}
                component={ListaPorEliminarPrensa}
              />
              <PrivateRoute
                path={`/prensa/ver-para-eliminar/:id`}
                component={VerParaEliminarPrensa}
              />

              {/* Categorias */}
              <PrivateRoute
                path={`/categorias/gestionar`}
                component={ListaCategorias}
              />
              <PrivateRoute
                path={`/categorias/ver/:id`}
                component={EditarSubcategorias}
              />

              {/* Configuracion Home Slide */}
              <PrivateRoute
                path={`/configuracion/home-slide/lista`}
                component={ListarHomeSlides}
              />
              <PrivateRoute
                path={`/configuracion/home-slide/lista-borradores`}
                component={ListaBorradoresHomeSlides}
              />
              <PrivateRoute
                path={`/configuracion/home-slide/listax`}
                component={ListarHomeSlidesX}
              />
              <PrivateRoute
                path={`/configuracion/home-slide/lista-por-eliminar`}
                component={ListaPorEliminarSlide}
              />
              <PrivateRoute
                path={`/configuracion/home-slide/nuevo`}
                component={NuevoHomeSlide}
              />
              <PrivateRoute
                path={`/configuracion/home-slide/ver/:id`}
                component={VerHomeSlide}
              />
              <PrivateRoute
                path={`/configuracion/home-slide/ver-borrador/:id`}
                component={VerBorradorHomeSlide}
              />
              <PrivateRoute
                path={`/configuracion/home-slide/aprobar/:id`}
                component={VerHomeSlideX}
              />
              <PrivateRoute
                path={`/configuracion/home-slide/ver-para-eliminar/:id`}
                component={VerParaEliminarSlide}
              />

              {/* Configuracion Home Estudia */}
              <PrivateRoute
                path={`/configuracion/home-estudia/ver/`}
                component={VerHomeEstudia}
              />
              <PrivateRoute
                path={`/configuracion/home-estudia/aprobar/`}
                component={VerHomeEstudiaX}
              />

              <PrivateRoute
                path={`/configuracion/home-estudia/revision/`}
                component={VerHomeEstudiaX}
              />

               {/* Configuracion Social networks */}
               <PrivateRoute
                path={`/social-networks/ver/`}
                component={VerSocialNetwork}
              />
              <PrivateRoute
                path={`/social-networks/aprobar/`}
                component={VerSocialNetworkX}
              />

              <PrivateRoute
                path={`/social-networks/revision/`}
                component={VerSocialNetworkX}
              />

              {/* Configuracion Home Estudia */}
              <PrivateRoute
                path={`/configuracion/home-encabezado/ver/`}
                component={VerHomeWhatsapp}
              />
              <PrivateRoute
                path={`/configuracion/home-encabezado/aprobar/`}
                component={VerHomeWhatsappX}
              />

              <PrivateRoute
                path={`/configuracion/home-encabezado/revision/`}
                component={VerHomeWhatsappX}
              />

              {/* Configuracion Usuarios CMS */}
              <PrivateRoute
                path={`/usuarios/cms/nuevo-usuario`}
                component={UsuarioCMS}
              />
              <PrivateRoute
                path={`/usuarios/cms/lista/`}
                component={ListaUsuariosCMS}
              />

              <PrivateRoute
                path={`/usuarios/cms/editar-usuario/:id`}
                component={UsuarioCMS}
              />
            </Layout>
          </Switch>
        </BrowserRouter>
        <ToastContainer />
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
